import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
// import CardHeader from '@material-ui/core/CardHeader'
// import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
// import CardActions from '@material-ui/core/CardActions'
// import Collapse from '@material-ui/core/Collapse'
// import Avatar from '@material-ui/core/Avatar'
// import Button from '@material-ui/core/Button'
// import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'

import { red } from '@material-ui/core/colors'

// import FavoriteIcon from '@material-ui/icons/Favorite'
// import ShareIcon from '@material-ui/icons/Share'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
// import MapMarker from '@material-ui/icons/Room'

// import ActivitiesDialog from './ActivitiesDialog'

const useStyles = makeStyles((theme) => ({
  card: {
    // maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },

  // activityCurs: {
  //   backgroundColor: '#fff2cc',
  // },
  // activityCercetare: {
  //   backgroundColor: '#b6d7a8',
  // },
  // activityEveniment: {
  //   backgroundColor: '#c27ba0',
  // },
  // activityRecreativ: {
  //   backgroundColor: '#e6b8af',
  // },

  // activityWA: {
  //   backgroundColor: '#b4a7d6',
  // },

  activityChip: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.tertiary.main,
    borderRadius: 0,
    // color: theme.palette.common.white,
  },
  timeChip: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    color: theme.palette.common.white,
  },
  durationChip: {
    marginRight: theme.spacing(2),
    // backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    // color: theme.palette.common.white,
  },
}))

export default function RecipeReviewCard({ data }) {
  const classes = useStyles()
  // const [expanded, setExpanded] = React.useState(false)

  // const handleExpandClick = () => {
  //   setExpanded(!expanded)
  // }

  // console.log(data)

  return (
    <Card
      className={clsx(classes.card, classes[`activity${data.activityType}`])}
      elevation={4}
    >
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {data.activityType && (
            <Chip label={data.activityType} className={classes.activityChip} />
          )}
          {data.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <Chip
            label={`${data.oraStart} - ${data.oraEnd}`}
            className={classes.timeChip}
          />
          <Chip
            label={`${data.duration * 60} min`}
            className={classes.durationChip}
          />
          {data.mentors}
        </Typography>
      </CardContent>
      {/* <CardActions disableSpacing>
        {data.location && (
          <IconButton
            aria-label="View on Google Maps"
            title={data.location}
            href={`https://goo.gl/maps/${[data.maps]}`}
            target="_blank"
          >
            <MapMarker />
          </IconButton>
        )}
        {data.dialog && <ActivitiesDialog data={data.dialog} />}
      </CardActions> */}
    </Card>
  )
}
