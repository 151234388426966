import React from 'react'
// import { NavLink } from 'react-router-dom'

import withWidth from '@material-ui/core/withWidth'
import {
  ThemeProvider,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import Icon from '@mdi/react'
import { mdiMail, mdiPhone, mdiMap } from '@mdi/js'

import ContactForm from './forms/ContactForm'
import SocialIcons from './SocialIcons'

import { MainTheme as theme } from '../themes/MainTheme'

import SiteLogo from './assets/SiteLogo'

const darkTheme = createMuiTheme({
  ...theme,
  palette: {
    type: 'dark',
    primary: { main: theme.palette.primary.main },
    secondary: { main: theme.palette.secondary.main },
  },
})

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(12, 8, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10, 8, 0),
    },
    backgroundColor: '#181818',
    color: 'white',
  },
  footerLeft: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
      textAlign: 'center',
    },
  },
  footerRight: {},
  siteLogo: {
    width: theme.spacing(20),
    marginRight: theme.spacing(2),
    verticalAlign: 'top',
    fill: theme.palette.common.white,
  },
  siteLogoName: {
    // display: 'inline-block',
    // lineHeight: `${theme.spacing(12)}px`,
  },
  contactText: {
    paddingLeft: theme.spacing(2),
    // color: theme.palette.tertiary.main,
    color: theme.palette.common.white,
    lineHeight: `${theme.spacing(3)}px`,
    fontWeight: 300,
    // [theme.breakpoints.down('xs')]: {
    //   padding: theme.spacing(0.75, 2),
    // },
  },
  contactIcon: {
    // width: `${theme.spacing(3)}px !important`,
    // height: `${theme.spacing(3)}px !important`,
    // marginLeft: theme.spacing(-4),
    // marginRight: theme.spacing(3),
    verticalAlign: 'middle',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  copyright: {
    margin: theme.spacing(12, -8, 0),
    padding: theme.spacing(10, 0, 8),
    backgroundColor: '#121212',
    color: theme.palette.grey[400],
    textAlign: 'center',

    '& span': {
      display: 'inline-block',
      lineHeight: '30px',
    },
    '& a': {
      verticalAlign: 'baseline',
    },
  },
  designedBy: {
    '&, &>a': {
      fontWeight: 400,
      color: theme.palette.grey[800],
      textTransform: 'none',
      verticalAlign: 'baseline',
    },
  },
}))

export default withWidth()(function Footer(props) {
  const classes = useStyles()
  const { config } = props
  const currentYear = new Date().getFullYear()

  function createMarkup(content) {
    return { __html: content }
  }

  return (
    <footer className={classes.root}>
      <ThemeProvider theme={darkTheme}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={5} className={classes.footerLeft}>
              <Grid container spacing={3}>
                <Grid item xs={12} align="center">
                  <SiteLogo
                    siteLogo={config.siteLogo}
                    siteLogoType={config.siteLogoType}
                    siteName={config.siteTitle}
                    siteLogoPosition="Footer"
                  />
                  {JSON.parse(config.showSiteNameFooter) && (
                    <Typography variant="h6" className={classes.siteLogoName}>
                      {config.siteTitle}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', alignItems: 'flex-end' }}
                >
                  <Grid container spacing={2}>
                    {config.siteContactEmail && (
                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          startIcon={
                            <Icon
                              path={mdiMail}
                              size={1}
                              color="white"
                              className={classes.contactIcon}
                            />
                          }
                          className={classes.contactText}
                          // href={`tel:${config.siteContactPhone}`}
                          target="_blank"
                          style={{ textTransform: 'none', cursor: 'default' }}
                        >
                          {config.siteContactEmail}
                        </Button>
                      </Grid>
                    )}
                    {config.siteContactPhone && (
                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          startIcon={
                            <Icon
                              path={mdiPhone}
                              size={1}
                              color="white"
                              className={classes.contactIcon}
                            />
                          }
                          className={classes.contactText}
                          href={`tel:${config.siteContactPhone}`}
                          target="_blank"
                          style={{ textTransform: 'none' }}
                        >
                          {config.siteContactPhone}
                        </Button>
                      </Grid>
                    )}
                    {config.siteContactAddress && (
                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          startIcon={
                            <Icon
                              path={mdiMap}
                              size={1}
                              color="white"
                              className={classes.contactIcon}
                            />
                          }
                          className={classes.contactText}
                          href={config.siteContactMapLink}
                          target="_blank"
                          style={{ textTransform: 'none' }}
                        >
                          <span
                            dangerouslySetInnerHTML={createMarkup(
                              config.siteContactAddress,
                            )}
                          />
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item xs={false} md={2} />
            </Hidden>
            <Grid item xs={12} md={5} className={classes.footerRight}>
              <ContactForm />
            </Grid>
          </Grid>
        </Container>
        <div className={classes.copyright}>
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SocialIcons
                  size=""
                  showIcons={[
                    {
                      name: 'Facebook',
                      url: 'https://www.facebook.com/mscitech',
                    },
                    // {
                    //   name: 'Instagram',
                    //   url: 'https://www.instagram.com/',
                    // },
                    // { name: 'Pinterest', url: 'https://pinterest.com' },
                    // { name: 'Twitter', url: 'https://twitter.com' },
                    // { name: 'Behance', url: 'https://behance.net' },
                    // { name: 'Dribbble', url: 'https://dribbble.com' },
                  ]}
                  // square
                />
                <Typography variant="caption" component="div">
                  <span>Copyright &copy; {currentYear} </span>
                  <Button
                    size="small"
                    color="inherit"
                    variant="text"
                    href={config.siteCopyrightOwnerURL}
                    target="_blank"
                  >
                    {config.siteCopyrightOwner}
                  </Button>
                  <br />
                  <span>Toate drepturile rezervate.</span>
                </Typography>
                {/* <Button
                  size="small"
                  color="inherit"
                  variant="text"
                  to="/ro/politica-de-confidentialitate"
                  component={NavLink}>
                  Politica de confidențialitate
                </Button> */}
              </Grid>
            </Grid>
            <br />
            <Typography
              variant="caption"
              component="div"
              className={classes.designedBy}
            >
              <Button
                disableElevation
                disableRipple
                variant="text"
                size="small"
                href="https://edgencia.ro"
                target="_blank"
              >
                designed and developed by edgencia
              </Button>
            </Typography>
          </Container>
        </div>
      </ThemeProvider>
    </footer>
  )
})
