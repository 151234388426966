import React from 'react'
// import PropTypes from 'prop-types'

import { makeStyles, fade } from '@material-ui/core/styles'
import { Card, Avatar, Tooltip, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  teamMemberCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'unset',
    backgroundColor: 'transparent',
  },

  teamMemberTooltip: {
    textAlign: 'center',
  },
  teamMemberAvatar: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
    border: `${fade(theme.palette.secondary.main, 0.57)} ${theme.spacing(
      1.5,
    )}px solid`,
    fontSize: theme.typography.h3.fontSize,

    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(28),
      height: theme.spacing(28),
      borderWidth: theme.spacing(2),
    },
    [theme.breakpoints.only('md')]: {
      width: theme.spacing(22),
      height: theme.spacing(22),
      borderWidth: theme.spacing(1.5),
    },
    [theme.breakpoints.only('sm')]: {
      width: theme.spacing(20),
      height: theme.spacing(20),
      borderWidth: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(18),
      height: theme.spacing(18),
      borderWidth: theme.spacing(1.5),
    },
  },
  teamMemberAvatarImg: {
    height: 'auto',
  },
}))

const TeamMember = ({ dataSource, imageFolder }) => {
  const classes = useStyles()
  // console.log(dataSource)
  return (
    <Card elevation={0} className={classes.teamMemberCard}>
      <Avatar
        alt={dataSource.fullName}
        src={
          dataSource.photo &&
          `https://msciteh.educatiepentrustiinta.ro/images/${imageFolder}/${
            dataSource.photo
          }`
        }
        classes={{
          root: classes.teamMemberAvatar,
          img: classes.teamMemberAvatarImg,
        }}
      >
        {dataSource.initials}
      </Avatar>
      <Tooltip
        title={
          dataSource.organisationFull
            ? dataSource.organisationFull
            : dataSource.organisation
        }
        placement="bottom"
        className={classes.teamMemberTooltip}
      >
        <div>
          <Typography variant="h6" component="div">
            {dataSource.fullName}
          </Typography>
          <Typography variant="button" component="div">
            {dataSource.position && dataSource.position}
            {dataSource.position && dataSource.organisation && ', '}
            {dataSource.organisation && dataSource.organisation}
          </Typography>
        </div>
      </Tooltip>
    </Card>
  )
}

TeamMember.propTypes = {}

export default TeamMember
