import React from 'react'

import ReactMarkdown from 'react-markdown'

import clsx from 'clsx'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'

import {
  Grid,
  Container,
  Dialog,
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Typography,
  Slide,
  Chip,
} from '@material-ui/core'

import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'

import CloseIcon from '@material-ui/icons/Close'

// import componentList from '../../core/ComponentIterator'

// const styles = theme => ({})

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    margin: 0,
    padding: theme.spacing(2, 3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  appBar: {
    position: 'relative',
  },
  title: {
    flex: 1,
    marginTop: 0,
    textTransform: 'uppercase',
  },
  dialogButton: {
    position: 'absolute',
    top: 50,
    left: 50,
  },
  customDialog: {
    '& h2:first-of-type': {
      marginTop: 0,
    },
  },
}))

const DialogTitle = props => {
  const classes = useStyles()
  const { children, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2, 3, 3),
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1, 2),
  },
}))(MuiDialogActions)

const Transition = React.forwardRef(function Transition(props, ref) {
  // console.log(props)
  return <Slide direction="up" ref={ref} {...props} />
})

function createMarkup(content) {
  return { __html: content }
}

const TopicContent = ({ dialogData, classes }) => {
  const domenii = dialogData.keywords && dialogData.keywords.split(',')
  // console.log('TopicDetails: ', dialogData)
  return (
    <Grid container spacing={3}>
      {dialogData.icon && (
        <Grid item xs={7} sm={4} md={3} style={{ margin: '0 auto' }}>
          <Box className={classes.cardImageContainer}>
            <img
              alt={dialogData.title}
              src={dialogData.icon}
              className={classes.cardImage}
            />
          </Box>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={dialogData.icon ? 9 : 12}
        className={classes.extraData}
      >
        {dialogData.domain && (
          <Typography
            component="p"
            color="textSecondary"
            gutterBottom
            className={classes.title}
          >
            {dialogData.domain}
          </Typography>
        )}
        {dialogData.title && (
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            className={classes.cardTitle}
          >
            <ReactMarkdown source={dialogData.title} />
          </Typography>
        )}
        {dialogData.keywords && (
          <Box className={clsx(classes.section, classes.sectionListFirst)}>
            <Typography
              className={classes.title}
              color="textSecondary"
              component="div"
            >
              <Typography
                component="span"
                className={clsx(classes.title, classes.titleLabel)}
              >
                Cuvinte cheie:
              </Typography>
              {domenii.map((item, index) => {
                return (
                  <Chip
                    size="small"
                    label={item}
                    key={index}
                    className={classes.chipDomenii}
                  />
                )
              })}
            </Typography>
          </Box>
        )}
        {dialogData.requirements && (
          <Box className={clsx(classes.section, classes.sectionList)}>
            <Typography variant="body1" component="div" gutterBottom>
              <Typography
                component="p"
                color="textSecondary"
                className={clsx(classes.title, classes.titleLabel)}
              >
                Cerințe specifice pentru candidați:
              </Typography>
              <b>{dialogData.requirements}</b>
            </Typography>
          </Box>
        )}
        {dialogData.shortDescription && (
          <Typography variant="body1" component="div" gutterBottom>
            <ReactMarkdown source={dialogData.shortDescription} />
          </Typography>
        )}
        {dialogData.image && (
          <Box>
            <img
              alt={dialogData.title}
              src={dialogData.image}
              className={classes.contentImage}
            />
          </Box>
        )}
        {dialogData.description && (
          <Typography variant="body2" component="div" gutterBottom>
            <ReactMarkdown source={dialogData.description} />
          </Typography>
        )}
        {dialogData.moreInfo && (
          <Box className={clsx(classes.section, classes.sectionListTop)}>
            <Typography variant="body2" component="div" gutterBottom>
              <Typography
                component="p"
                color="textSecondary"
                className={clsx(classes.title, classes.titleLabel)}
              >
                Alte informații:
              </Typography>
              <ReactMarkdown source={dialogData.moreInfo} />
            </Typography>
          </Box>
        )}
        {dialogData.organisation && (
          <Box className={classes.section}>
            <Typography
              // align="right"
              className={classes.title}
              color="textSecondary"
              component="div"
              gutterBottom
            >
              <Typography gutterBottom className={classes.title}>
                Organizatori:
              </Typography>
              {dialogData.organisation && <b>{dialogData.organisation}</b>}
            </Typography>
          </Box>
        )}
        {dialogData.mentors && (
          <Box className={classes.section}>
            <Typography
              // align="right"
              className={classes.title}
              color="textSecondary"
              component="div"
              gutterBottom
            >
              <Typography gutterBottom className={classes.title}>
                Mentori:
              </Typography>
              {dialogData.mentors && <b>{dialogData.mentors}</b>}
            </Typography>
          </Box>
        )}
        {dialogData.guests && (
          <Box className={classes.section}>
            <Typography
              // align="right"
              className={classes.title}
              color="textSecondary"
              component="div"
              gutterBottom
            >
              <Typography gutterBottom className={classes.title}>
                Invitați:
              </Typography>
              {dialogData.guests && <b>{dialogData.guests}</b>}
            </Typography>
          </Box>
        )}
      </Grid>
      {dialogData.content && (
        <Grid
          item
          xs={12}
          dangerouslySetInnerHTML={createMarkup(dialogData.content)}
        />
      )}
    </Grid>
  )
}

export default withWidth()(function FullScreenDialog(props) {
  const localClasses = useStyles()
  const theme = useTheme()

  const {
    dialogData,
    buttonColor,
    buttonVariant,
    buttonSize,
    buttonText,
    buttonEndIcon,
    classes,
  } = props

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = e => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(true)
  }

  const handleClose = e => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(false)
  }

  // const dialogData = props.data
  // const ComponentIterator = componentList[dialogData.component]

  return (
    <div
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
      }}
      onScroll={e => {
        e.stopPropagation()
        e.preventDefault()
      }}
      style={{
        flexGrow: 1,
        display: 'flex',
        // flexDirection: 'column',
        // justifyContent: `${props.justifyContent}`,
        justifyContent: `flex-end`,
      }}
    >
      <Button
        fullWidth={false}
        variant={buttonVariant ? buttonVariant : 'text'}
        color={buttonColor ? buttonColor : 'default'}
        size={buttonSize ? buttonSize : 'medium'}
        onClick={handleClickOpen}
        endIcon={buttonEndIcon}
        className={classes.actionButton}
        style={{
          // flex: '0 1 auto',
          // alignSelf: `${dialogData.alignItems}`,
          marginRight: 8,
        }}
      >
        {buttonText}
      </Button>
      <Dialog
        aria-labelledby="scroll-dialog-title"
        fullScreen={isWidthDown('md', props.width) ? true : false}
        maxWidth="lg"
        fullWidth={true}
        scroll="paper"
        hideBackdrop
        elevation={24}
        open={open}
        onClose={handleClose}
        // TransitionComponent={React.forwardRef(function Transition(props, ref) {
        //   return <Slide direction={dialogData.transition} ref={ref} {...props} />
        // })}
        TransitionComponent={Transition}
        className={localClasses.customDialog}
      >
        {isWidthDown('md', props.width) ? (
          <React.Fragment>
            <AppBar className={localClasses.appBar}>
              <Toolbar>
                <Typography
                  variant="h6"
                  id="scroll-dialog-title"
                  className={localClasses.title}
                  style={{ marginLeft: theme.spacing(2) }}
                >
                  {/* {dialogData.content.title} */}
                  Detalii temă
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>

            <Container maxWidth="md" style={{ margin: theme.spacing(6, 0, 3) }}>
              <TopicContent dialogData={dialogData} classes={classes} />
            </Container>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DialogTitle id="scroll-dialog-title" onClose={handleClose}>
              {/* {dialogData.content.title} */}
              Detalii temă
            </DialogTitle>
            {dialogData && (
              <DialogContent dividers>
                <TopicContent dialogData={dialogData} classes={classes} />
                {/* <Typography
                  component="h1"
                  variant={isWidthUp('sm', props.width) ? 'h3' : 'h4'}
                  gutterBottom
                >
                  {dialogData.content.title}
                </Typography>
                <br />
                {dialogData.content.trainers && (
                  <React.Fragment>
                    <div>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                        gutterBottom
                      >
                        Formatori:
                      </Typography>
                      <Typography
                        variant="body1"
                        gutterBottom
                        dangerouslySetInnerHTML={createMarkup(
                          dialogData.content.trainers,
                        )}
                      />
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.moderators && (
                  <React.Fragment>
                    <div>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                        gutterBottom
                      >
                        Moderatori:
                      </Typography>
                      <Typography
                        variant="body1"
                        gutterBottom
                        dangerouslySetInnerHTML={createMarkup(
                          dialogData.content.moderators,
                        )}
                      />
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.duration && (
                  <React.Fragment>
                    <div>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                        gutterBottom
                      >
                        Timp alocat: {dialogData.content.duration}
                      </Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.goals && (
                  <React.Fragment>
                    <div>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                        gutterBottom
                      >
                        Scopul atelierului:
                      </Typography>
                      <Typography gutterBottom>
                        {dialogData.content.goals}
                      </Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.guests && (
                  <React.Fragment>
                    <div>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                        gutterBottom
                      >
                        Invitați:
                      </Typography>
                      <Typography component="ul">
                        {dialogData.content.guests.map((guest, i) => {
                          return (
                            <li
                              key={i}
                              dangerouslySetInnerHTML={createMarkup(guest)}
                            />
                          )
                        })}
                      </Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.targetAudience && (
                  <React.Fragment>
                    <div>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                        gutterBottom
                      >
                        Publicul țintă:
                      </Typography>
                      <Typography gutterBottom>
                        {dialogData.content.targetAudience}
                      </Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.invited && (
                  <React.Fragment>
                    <div>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                        gutterBottom
                      >
                        Prezenți în sală:
                      </Typography>
                      <Typography gutterBottom>
                        {dialogData.content.invited}
                      </Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.objectives && (
                  <React.Fragment>
                    <div>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                        gutterBottom
                      >
                        Obiective:
                      </Typography>
                      <Typography component="ul">
                        {dialogData.content.objectives.map((objective, i) => {
                          return (
                            <li
                              key={i}
                              dangerouslySetInnerHTML={createMarkup(objective)}
                            />
                          )
                        })}
                      </Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.themes && (
                  <React.Fragment>
                    <div>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                        gutterBottom
                      >
                        Teme abordate:
                      </Typography>
                      <Typography component="ol">
                        {dialogData.content.themes.map((theme, i) => {
                          return (
                            <li
                              key={i}
                              dangerouslySetInnerHTML={createMarkup(theme)}
                            />
                          )
                        })}
                      </Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.image && (
                  <React.Fragment>
                    <div>
                      <img
                        src={`https://cnces.educatiepentrustiinta.ro/images/ateliere/${
                          dialogData.content.image
                        }`}
                        alt={dialogData.content.title}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.info && (
                  <React.Fragment>
                    <div>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                        gutterBottom
                      >
                        Descriere:
                      </Typography>
                      <Typography
                        gutterBottom
                        dangerouslySetInnerHTML={createMarkup(
                          dialogData.content.info,
                        )}
                      />
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.resources && (
                  <React.Fragment>
                    <div>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                        gutterBottom
                      >
                        Resurse necesare:
                      </Typography>
                      <Typography gutterBottom>
                        {dialogData.content.resources}
                      </Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )} */}
              </DialogContent>
            )}
            {/* {props.children && (
              <DialogContent dividers>{props.children}</DialogContent>
            )} */}
            {dialogData.hasActionButton && (
              <DialogActions>
                {dialogData.buttonType ? (
                  <Button
                    autoFocus
                    color="inherit"
                    type={dialogData.buttonType}
                  >
                    {dialogData.buttonActionText}
                  </Button>
                ) : (
                  <Button autoFocus color="inherit" onClick={handleClose}>
                    {dialogData.buttonActionText}
                  </Button>
                )}
              </DialogActions>
            )}
          </React.Fragment>
        )}
      </Dialog>
    </div>
  )
})
