import {
  createMuiTheme,
  // fade
} from '@material-ui/core'
// import { dark } from '@material-ui/core/styles/createPalette'
import amber from '@material-ui/core/colors/amber'

const theme = createMuiTheme({
  palette: {
    // type: 'dark',
    primary: { main: '#50066a' },
    secondary: { main: '#95c11f' },
    tertiary: {
      main: amber[500],
      light: amber[300],
      dark: amber[700],
      contrastText: '#50066a',
    },
  },
  shape: {
    verticalSpacer: 4,
  },
})

const MainTheme = {
  ...theme,
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        color: theme.palette.common.white,
      },
      positionFixed: {
        top: theme.spacing(3),
        right: theme.spacing(3),
        left: theme.spacing(3),
        width: `calc(100vw - ${theme.spacing(8)}px)`,
        [theme.breakpoints.between('sm', 'md')]: {
          width: `calc(100vw - ${theme.spacing(6)}px)`,
        },
        [theme.breakpoints.down('xs')]: {
          top: theme.spacing(theme.shape.verticalSpacer / 2),
          right: theme.spacing(theme.shape.verticalSpacer / 2),
          left: theme.spacing(theme.shape.verticalSpacer / 2),
          width: `calc(100vw - ${theme.spacing(theme.shape.verticalSpacer)}px)`,
        },
      },
    },
    MuiButton: {
      root: {
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        '&:last-child': {
          marginRight: 0,
        },
      },
      contained: {
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.primary.main,
        fontWeight: 600,
        '&:hover': {
          backgroundColor: theme.palette.tertiary.dark,
        },
      },
      containedPrimary: {
        fontWeight: 500,
      },
      containedSecondary: {
        fontWeight: 600,
      },
    },
    MuiTypography: {
      h3: {
        '&$gutterBottom': { margin: theme.spacing(6, 0, 4) },
      },
      h4: {
        margin: theme.spacing(6, 0, 4),
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 0,
      },
    },
  },
}

export { MainTheme }
