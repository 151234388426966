import React from 'react'
// import PropTypes from 'prop-types'

import {
  makeStyles,
  // fade
} from '@material-ui/core/styles'
import { Card, CardMedia, Tooltip, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  logoOrganisationCard: {},

  cardMedia: {
    margin: theme.spacing(2),
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '80%',
    backgroundSize: 'contain',
    '&>div': {
      marginTop: `calc(-50% - ${theme.spacing(3)}px)`,
      fontSize: theme.typography.h4.fontSize,
      [theme.breakpoints.only('md')]: {
        marginTop: `calc(-50% - ${theme.spacing(2.5)}px)`,
        fontSize: theme.typography.h5.fontSize,
      },
    },
  },
  cardContent: {
    '&, &:last-child': { padding: theme.spacing(1, 2) },
  },

  logoOrganisationTooltip: {
    textAlign: 'center',
  },
}))

const LogoOrganisation = ({ dataSource, imageFolder }) => {
  const classes = useStyles()
  // console.log(dataSource)
  return (
    <Card elevation={0} className={classes.logoOrganisationCard}>
      <Tooltip
        title={
          dataSource.organisationFull
            ? dataSource.organisationFull
            : dataSource.organisation
        }
        placement="bottom"
        className={classes.logoOrganisationTooltip}
      >
        {dataSource.logo ? (
          <CardMedia
            image={`https://msciteh.educatiepentrustiinta.ro/images/${imageFolder}/${
              dataSource.logo
            }`}
            className={classes.cardMedia}
          />
        ) : (
          <CardMedia className={classes.cardMedia}>
            <Typography variant="button" component="div">
              {dataSource.organisation && dataSource.organisation}
            </Typography>
          </CardMedia>
        )}
      </Tooltip>
    </Card>
  )
}

LogoOrganisation.propTypes = {}

export default LogoOrganisation
