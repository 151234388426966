import React from 'react'
// import PropTypes from 'prop-types'

// import ReactMarkdown from 'react-markdown'
import { withGoogleSheets } from 'react-db-google-sheets'

import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import RouteScrollTop from '../comps/RouteScrollTop'
import components from '../comps/components'

const useStyles = makeStyles(theme => ({
  teamContainer: {
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}))

function Team(props) {
  const classes = useStyles()
  const { dataSource, db, spacing } = props
  const team = db[dataSource]

  return (
    // <Container maxWidth="lg">
    <React.Fragment>
      <RouteScrollTop />
      <Grid container spacing={spacing} className={classes.teamContainer}>
        {dataSource &&
          team.map((data, index) => {
            // console.log(data)
            const RenderComponent = components[data.type]
            if (JSON.parse(data.display)) {
              return (
                <Grid item {...JSON.parse(data.props).grid} key={index}>
                  <RenderComponent dataSource={data} imageFolder={dataSource} />
                </Grid>
              )
            }
            return null
          })}
      </Grid>
    </React.Fragment>
    // </Container>
  )
}

Team.propTypes = {}

export default withGoogleSheets()(Team)
